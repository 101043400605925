import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “We recently had my daughter’s 4th birthday party here and had a great experience. All the kids had SO much fun playing. My son has already told me he wants his party there and a guest booked a party while there as well. We had a private party room that can be decorated and WRTS even provided goody bags to match the bluey theme. Overall I would recommend for parties or open play. I appreciate that they have something for all kids!”                            </p>
                            <p className="overview">Brittany H.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “My son and I visited on Saturday and we really enjoyed our selves! I love the mission, atmosphere, and the staff were very friendly. The location was very clean and organized. I loved that there was someone for kids of all ages, it is plenty of parking, and I love that parents can be apart of the fun also. I’d def recommend going here and throwing a birthday party here.
”                            </p>
                            <p className="overview">Jahnae K. </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We celebrated our son’s 5th birthday this past Saturday and has a blast! They even got the party favors together, arranged the food delivery from 401 next door ( we love 401) and the complete event was so stress free for a parents of the birthday kid. The whole place has been cleaned prior to our arrival although there was a party that ended just 30 min before. They took care of decorations and also had shoe covers for parents so we could walk freely on the blue mats. Our guests kids and our son thoroughly enjoyed the play structures here and also got to calm down with painting and Lego wall structures. It’s really a fun place with something to offer for all age groups. I’m so looking forward to book them for a parents night out as I know our son will have a great time there.
”                            </p>
                            <p className="overview">Priyanka I. </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This place is amazing! It has lots of activities to keep children busy. There is even a section for teens. I love the calming room to allow for some quiet calming time. The zip line is exceptionally fun. The owner, David, is great! He is obviously passionate about making this facility top notch.<br />
It is definitely a fun place to take the kiddos!"</p>
                            <p className="overview">
                           Beverly B.  </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                                “We went here on a rainy day with a 3,4,5 & 6 year old and it was perfection! There was plenty for each of them to do - lots of running around space. Great obstacles and activities for climbing and swinging, a darling little quiet space and some pretend play items. The owner was so welcoming and kind. Highly recommend!"</p>
                            <p className="overview">
                                Meredith M.
                                                        </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
